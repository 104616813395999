@import '../../../utils/stylesVariables';

.cp {
    &-header-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &-header-buttons {
        display: flex;
    }
    &-header-button {
        height: 40px;
        margin-left: 8px;
        border: 0;
        &:hover {
            color: rgba(0, 0, 0, 0.65);
        }
        &:focus {
            color: rgba(0, 0, 0, 0.65);
        }
        &__red {
            background-color: $red;
            border-color: $red;
            color: $white;
            &:hover,
            &:focus {
                background: $lighterRed;
                border-color: $lighterRed;
                color: $white;
            }
        }
    }
}

.cp-actions {
    border: 0;
    .anticon-edit {
        svg {
            height: 20px;
            width: 20px;
        }
    }
    .anticon.anticon-ellipsis {
        font-size: 20px;
    }
    &__menu {
        &__red {
            color: red;
        }
        a {
            span {
                margin-right: 8px;
            }
        }
    }
}

.refresh-modal {
    .ant-modal-header {
        border: 0;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 24px;
        .ant-modal-title {
            color: $primary;
            font-size: 28px;
            font-weight: bold;
        }
    }
    .ant-modal-body {
        border: 0;
        p {
            color: $darkenGray;
        }
    }

    .ant-modal-footer {
        border: 0;
        padding: 24px;
        .refresh-modal__button {
            width: 104px;
            height: 40px;
            border: 0;
            border-radius: 24px;
            &__red {
                background-color: var(--accent-color);
                color: white;
            }
            &__gray {
                background-color: $grey;
                color: $primary;
            }
        }
    }
}
