.unfinished-rounds {
  &__header-tools {
    display: flex;
    margin-bottom: 8px;
    .custom-button {
      margin-left: auto;
    }
  }
}

.unfinished-rounds-process-button {
  margin-bottom: 8px;
}

.unfinished-round-action-modal {
  .ant-modal-body {
    width: 400px;
  }
}

