@import '../../utils/stylesVariables';

.main-header {
    display: flex;
    width: 100%;
    height: auto;
    background-color: $grey;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 10;
    &__title {
        display: none;
        color: #0b1022;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-left: 50px;
    }
    &__search-field {
        width: 300px;
        height: 32px;
    }
    &__dropdown-container {
        max-width: calc(100% - 416px);
        height: 32px;
        display: flex;
        &.open {
            z-index: 3;
        }
    }
}

.dropdown-item {
    align-items: center;
    color: #0b1022;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 500;
    display: flex;
    height: 45px;
    p {
        line-height: 36px;
    }
}

.header-dropdown {
    .ant-dropdown-menu-item > a,
    .ant-dropdown-menu-submenu-title > a {
        display: flex;
        color: #0b1022;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
    }
    .ant-dropdown-menu-item:hover {
        background-color: #e6f7ff;
    }
    &__icon {
        width: 26px;
        font-size: 22px;
        text-align: center;
        margin-right: 23px;
    }
}

.header-search {
    width: 400px;
    margin-right: 16px;
}

@import 'MainHeaderMedia';
