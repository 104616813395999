@import 'utils/stylesVariables';

:root {
    --default-accent: #cf0920;
    --koala-accent: #ffb211;
    --default-background: url('./images/welcome-bg-bo-min.png');
    --koala-background: url('./images/welcome-bg-bo-koala-min.png');
    --default-login-logo: url('./images/BO-logo.png');
    --koala-login-logo: url('./images/KOALA-login-logo.png');
    --default-logo: url('./images/logo.png');
    --koala-logo: url('./images/KOALA-logo.png');
    --background-image: var(--default-background);
    --login-logo: var(--default-login-logo);
    --accent-color: var(--default-accent);
    --logo: var(--default-logo);
}

body {
    height: auto;
    font-family: futura-pt, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: $primary;
    min-height: 375px;
    &.desktop {
        min-width: 769px;
    }
}

button,
input,
optgroup,
select,
textarea {
    font-family: futura-pt, sans-serif;
}

#root {
    width: 100%;
    background-color: $grey;
    height: 100vh;
}
.ant-modal-wrap {
    overflow: hidden;
}

.ant-modal-mask {
    display: none;
}

.ant-btn {
    letter-spacing: 0.5px;
}

.ant-select-selection__rendered {
    width: 100%;
}

.toTop {
    opacity: 0;
    border-radius: 20px;
    outline: 0;
    position: fixed;
    z-index: 10;
    border: 0;
    width: 40px;
    height: 40px;
    background-color: #cf0920;
    color: white;
    bottom: 15px;
    right: 15px;
    transition: 0.3s;
}

.toTop.visible {
    opacity: 1;
    cursor: pointer;
}

.ant-table.ant-table-middle {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
