.unfinished-round-jobs-modal {
  .ant-modal-body {
    width: 350px;
  }
}

.unfinished-round-jobs-modal__datepicker {
  width: 300px;
}

.unfinished-round-jobs-modal__status {
  width: 250px;
}

.unfinished-round-jobs-run-button {
  margin-bottom: 8px;
}

.unfinished-round-jobs-modal-form-item {
  display: block;
  width: 350px;
}

.status-message {
  margin-left: 20px;
}