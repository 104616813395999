@import '../../utils/stylesVariables';

.custom-button {
    height: 40px;
    min-width: 104px;
    border-radius: 20px;
    border: 0;
    outline: 0;
    transition: 0.2s;
    cursor: pointer;
    padding-left: 16px;
    padding-right: 16px;
    &__main {
        background-color: var(--accent-color);
        color: white;
        &:hover {
            background-color: #b9071c;
        }
        &__disabled {
            background-color: #cf092059;
            cursor: not-allowed;
        }
    }
    &__secondary {
        background-color: $grey;
        color: $primary;
        &:hover {
            background-color: #e2e6ed;
        }
        &__disabled {
            background-color: #f0f3f8;
            cursor: not-allowed;
        }
    }
    &__small {
        height: 32px;
    }
    &__white {
        background-color: white;
    }
}
